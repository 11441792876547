
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import moment, { Moment } from 'moment';
import { Range } from '@/interfaces/components/Range';

interface OpeningHour {
  weekday: number;
  openedAt: string | null;
  closedAt: string | null;
  openedAtMenu: false;
  closedAtMenu: false;
}

@Component
export default class TimeSelector extends Vue {
  @Prop({ type: Array }) public openingHours!: OpeningHour[];
  @Prop({ type: Object }) public range!: Range;
  @Prop({ type: Boolean, default: true }) public isDefaultTime?: Boolean;

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };
  public hour: OpeningHour = {
    weekday: 0,
    openedAt: this.isDefaultTime ? moment().format('HH:mm') : '',
    closedAt: this.isDefaultTime ? moment().format('HH:mm') : '',
    openedAtMenu: false,
    closedAtMenu: false,
  };

  public getHours() {
    return {
      openedAt: this.getUtcTime(this.hour.openedAt!),
      closedAt: this.getUtcTime(this.hour.closedAt!),
    };
  }

  public setHours(hour: OpeningHour): void {
    if (hour) {
      this.hour = {
        weekday: hour.weekday,
        openedAtMenu: false,
        closedAtMenu: false,
        // @ts-ignore
        openedAt: this.toLocaleTime(hour.openedAt),
        // @ts-ignore
        closedAt: this.toLocaleTime(hour.closedAt),
      };
    }
  }

  public getUtcTime(time: string): string | null {
    if (!moment(time, 'HH:mm').isValid()) {
      return null;
    }

    return moment(time, 'HH:mm').subtract(120, 'minutes').format('HH:mm');
  }

  public toLocaleTime(time: string): string | null {
    if (!moment(time, 'HH:mm').isValid()) {
      return null;
    }

    return moment(time, 'HH:mm').add(120, 'minutes').format('HH:mm');
  }

  public validate(): Promise<boolean> {
    return this.$refs.observer.validate();
  }

  change() {
    setTimeout(() => {
      this.$emit('time', this.getHours());
    }, 0);
  }

  mounted() {
    if (this.isDefaultTime) {
      this.setHours(this.openingHours[moment(this.range.to).day()]);
      this.$emit('time', this.getHours());
    }
  }
}
